import { startMap, getParamsData } from '../../map/startMap.js';
import { getText } from '../../map/templates/popup/popupCustomize.js';
// import { activeMapOrNot } from '../../map/btn_display_map.js';
import { removeContour } from '../../map/btn_display_map.js';
const displayAllFeaturesProperties = (params) => {

  map.on('render', afterChangeComplete); // warning: this fires many times per second!
    function afterChangeComplete () {
      if (!map.loaded()) { return } // still not loaded; bail out.

      // now that the map is loaded, it's safe to query the features:
      const features = params.map.queryRenderedFeatures(
        {layers: [`${params.sourceLayerName}-id`]}
      );
      if (features.length > 0) {
        if (features[0].properties) {
          insertPopupDemo(features[0].properties)
          insertBtnNewPopup(features[0].properties)

          // openPopup({'params': params, 'feature': features[0]})
          removeContour(params);
          map.off('render', afterChangeComplete); // remove this handler now that we're done.

        }

      }
    }
}

const insertPopupDemo = (properties) => {
  const popupDemo = document.getElementById('popup-demo')
    // popupDemo.insertAdjacentHTML('beforeend', `<li>${propertie}</li>`)
  let key_rename = ''
  for (const [key, value] of Object.entries(properties)) {
    if (key == "area_name") {
      key_rename = 'libelle_geographique'
    } else {
      key_rename = key
    }
    popupDemo.insertAdjacentHTML('beforeend', `<li>${key_rename} : ${value}</li>`)
  }


}

const insertBtnNewPopup = (properties) => {
  const btns = document.querySelectorAll('.btn-new-popup-ids')
  btns.forEach((btn) => {
    btn.href = btn.href + `?properties=${Object.keys(properties)}`
    btn.classList.toggle('d-none')

  })
}

const openPopup = (parameter) => {

  const coord  = getCoordinates(parameter)

  const popup = new mapboxgl.Popup({ closeOnClick: false })

  // const text = getText(parameter)

  const text = getText({'info_properties': parameter.feature.properties,
                          'info_popupCustomize': parameter.params.popupCustomize
                        })
  const htmlText = `<ul class="popup-atlas-culture ul-style-none">${text.join(" ")}</ul>`
  popup.setLngLat(coord)
      .setHTML(htmlText)
      .addTo(map);

}

// const getText = (parameter) => {

//   let text = []

//   for (const [key, value] of Object.entries(parameter.feature.properties)) {
//     if (parameter.params.popupCustomize.length == 0) {
//       text.push(`<li> ${key} : ${value} </li>`)
//     } else {
//       parameter.params.popupCustomize.forEach((popupCustome) => {
//         if (popupCustome.matching == key) {
//           if (typeof(value) === 'number') {
//             value = Math.round(value)
//           }
//           text.push(`<li class='d-flex'>${popupCustome.content_before} <strong>${value}</strong> ${popupCustome.content_after}</li>`)
//         }
//       })
//     }
//   }
//   return text
// }


const getCoordinates = (parameter) => {
  let coord = []
  if (parameter.feature.geometry.coordinates.length == 2) {
    coord = parameter.feature.geometry.coordinates
  } else if (parameter.feature.geometry.coordinates[0].length == 2) {
    coord = parameter.feature.geometry.coordinates[0]
  } else if (parameter.feature.geometry.coordinates[0][0].length == 2) {
    coord = parameter.feature.geometry.coordinates[0][0]
  } else if (parameter.feature.geometry.coordinates[0][0][0].length == 2) {
    coord = parameter.feature.geometry.coordinates[0][0][0]
  }

  return coord
}


export { displayAllFeaturesProperties }
