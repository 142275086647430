
const popupCustomize = (parameter) => {
  if (parameter.e.features.length > 0) {
    const text = getText({'info_properties': parameter.e.features[0].properties,
                          'info_popupCustomize': parameter.params.popupCustomize,
                          "parameter": parameter
                        })


    const htmlText = `<ul class="popup-atlas-culture ul-style-none">${text.join(" ")}</ul>`
    parameter.popup.setLngLat(parameter.e.lngLat)
      .setHTML(htmlText)
      .addTo(parameter.params.map);
  }
}

const getText = (info_params) => {
  const informationsHash = info_params.info_properties
  let text = []
  for (let [key, value] of Object.entries(informationsHash)) {
    if (info_params.info_popupCustomize.length == 0) {

      text.push({'text':`<li> ${key} : ${value} </li>`,
                  'position': null
                })
    } else {
      const array = sortPosition(info_params.info_popupCustomize)
      array.forEach((popupCustome) => {
        let content_after = popupCustome.content_after
        if (popupCustome.matching == key) {

          if (typeof(value) === 'number') {
            if (key == 'code_postal') {
              if (info_params.info_properties["code_insee"] !== undefined) {
                value = String(value)
                if (typeof(info_params.info_properties["code_insee"]) == typeof(String('string'))) {
                  if (info_params.info_properties["code_insee"].startsWith('0')) {
                    value = `0${value}`
                  } else {
                    value
                  }
                } else {
                  value
                }
              }
            } else {
              value = value.toFixed(2);
              value = separatorMillier(value);
              value = value.replace(' .',',');
              value = value.replace(',00','');
              if (value == 999 && info_params.parameter.params.urlData.includes("depenses-culturelles-des-intercommunalites-total")) {
                value = "hors champs";
                content_after = "";
              }
            }
          }
          if (value == 'nc') {
            value = 'donnée non disponible';
            content_after = '';
          }
          // insecable
          const class_bolder = applyBolder(popupCustome.bolder_value)

          text.push(
            {'text':`
              <li class='d-flex flex-column'>
                <span class="d-flex">
                  <span class="">${popupCustome.content_before}</span>
                  <span class="${is_numeric_insecable(value)} ${class_bolder}">${is_link(value)}</span>
                  <span class='ml-2'>${content_after}</span>
                </span>

              </li>`,
              'position': popupCustome.position
            })

        }
      })
    }
  }
  text = sortPosition(text)

  text = hashToArray(text)

  return text
}

const hashToArray = (hash) => {
  let array = []
  for (const [key, value] of Object.entries(hash)) {
    array.push(value.text)
  }
  return array
}
const sortPosition = (array) => {
  array.sort(function (a, b) {
    if (a.position != null) {
      return a.position - b.position;
    }
  });

  return array
}


const separatorMillier = (a, b) => {
  a = '' + a;
  b = b || ' ';
  var c = '',
      d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substr(1);
  }
  for (var i = a.length-1; i >= 0; i--) {
    c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
    d++;
  }
  return c;
}

const applyBolder = (bolder_value) => {
  if (bolder_value) {
    return 'font-weight-bold'
  } else {
    return ''
  }
}

const is_numeric_insecable = (str) => {
  if (/^\d+$/.test(str)) {
    return 'insecable'
  } else {
    return ''
  }
}

const is_link = (value) => {
  const expression = "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?";
  const regex = new RegExp(expression);

  if (value.match(regex)) {
    return `<a style="font-size:0.78rem;" href="${value}" target='_blank'>
    ${value}
    </a>`
  } else {
    return value
  }
}

export { popupCustomize, getText }
