const buildParamsAplat = (colors,steps,metrique) => {

  if (steps.length == 2) {

    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    manageUndefined(colors[1],colors[0]),
    ]
    return paramsAplat
  } else if (steps.length == 3) {
    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    colors[2],
    parseFloat(steps[2]),
    manageUndefined(colors[3],colors[2]),
    ]

    return paramsAplat

  } else if (steps.length == 4) {
    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    colors[2],
    parseFloat(steps[2]),
    colors[3],
    parseFloat(steps[3]),
    manageUndefined(colors[4],colors[3]),
    ]
    return paramsAplat
  } else if (steps.length == 5) {
    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    colors[2],
    parseFloat(steps[2]),
    colors[3],
    parseFloat(steps[3]),
    colors[4],
    parseFloat(steps[4]),
    manageUndefined(colors[5],colors[4]),
    ]

    return paramsAplat
  } else if (steps.length == 6) {

    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    colors[2],
    parseFloat(steps[2]),
    colors[3],
    parseFloat(steps[3]),
    colors[4],
    parseFloat(steps[4]),
    colors[5],
    parseFloat(steps[5]),
    manageUndefined(colors[6],colors[5]),
    ]
    return paramsAplat
  } else if (steps.length == 7) {

    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    colors[2],
    parseFloat(steps[2]),
    colors[3],
    parseFloat(steps[3]),
    colors[4],
    parseFloat(steps[4]),
    colors[5],
    parseFloat(steps[5]),
    colors[6],
    parseFloat(steps[6]),
    manageUndefined(colors[7],colors[6]),
    ]
    return paramsAplat
  } else if (steps.length == 8) {

    const paramsAplat = ['step',["get", `${metrique}`],
    colors[0],
    parseFloat(steps[0]),
    colors[1],
    parseFloat(steps[1]),
    colors[2],
    parseFloat(steps[2]),
    colors[3],
    parseFloat(steps[3]),
    colors[4],
    parseFloat(steps[4]),
    colors[5],
    parseFloat(steps[5]),
    colors[6],
    parseFloat(steps[6]),
    colors[7],
    parseFloat(steps[7]),
    manageUndefined(colors[8],colors[7]),
    ]

    return paramsAplat
  }


}


const manageUndefined = (value, value_after) => {
  if (value) {
    return value;
  } else {
    return value_after;
  }
}

export { buildParamsAplat }
