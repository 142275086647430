import { checkSuperpose } from './checkSuperpose.js'
import { displayAllFeaturesProperties } from '../form/popup/displayAllFeaturesProperties.js'
const btn_display_map = (params) => {
// btn,map,sourceLayerName,superpose,btns,zoom,sourceLayerNameCustome

  if (params.displayMapOneInfo) {
    activeMapOrNot(params);
    displayAllFeaturesProperties(params);

  } else {
    params.btn.addEventListener('click', (event) => {
      activeMapOrNot(params);
      removeContour(params);
    })
  }
}

const activeMapOrNot = (params) => {
  // btn,map,sourceLayerName,superpose,btns,zoom,sourceLayerNameCustome
  const visible = params.map.getLayoutProperty(`${params.sourceLayerName}-id`, 'visibility')
  const superposed = params.superpose === 'true'
  checkSuperpose({'superpose':  superposed,
      'btns': params.btns,
      'current_btn': params.btn,
      'sLN': params.sourceLayerName,
      'map': params.map,
      'sLNC': params.sourceLayerNameCustome})


  if (visible == 'none') {
    let getZoom = ''
    if (params.zoom == '0') {
      getZoom = params.map.getZoom();
    } else {
      getZoom = params.zoom
    }
    fly(map,map.getCenter(),getZoom)
  } else {
    fly(map,map.getCenter(),4)
  }
}


const fly = (map,center,zoom) => {

    map.flyTo({
        center: center, zoom: zoom
    });
}

const removeContour = (params) => {
  params.map.setLayoutProperty('regionLayer', 'visibility', 'none');
  params.map.setLayoutProperty('departementLayer', 'visibility', 'none');
  params.map.setLayoutProperty('communeLayer', 'visibility', 'none');
  params.map.setLayoutProperty('regionLine', 'visibility', 'none');
  params.map.setLayoutProperty('departementLine', 'visibility', 'none');
  params.map.setLayoutProperty('communeLine', 'visibility', 'none');
}

const btnDisplayAllSelected = () => {
  const btns = document.querySelectorAll('.btns-tilsets-all-selected')
  btns.forEach((btn) => {
    btn.addEventListener('click',(event) => {

      event.currentTarget.classList.toggle("btn-active")

      const tilsetBtns = event.currentTarget.nextElementSibling.querySelectorAll('button')

      tilsetBtns.forEach((tilsetBtn) => {
        if (!(tilsetBtn.classList.contains("btn-active") && event.currentTarget.classList.contains("btn-active"))) {
          tilsetBtn.click();
        }
      })
    })
  })
}

export { btn_display_map, removeContour, btnDisplayAllSelected }


