import { layerMetric } from './layers.js';
import { btn_display_map,btnDisplayAllSelected } from './btn_display_map.js';
import { goToRegion } from './goToRegion.js';
import { getSourceContour } from './templates/zoneLayer.js';
import { breakpoints } from '../../responsive/breakpoints.js';
import { addMultipleZoneLayer } from './multipleZoneLayer/addMultipleZoneLayer.js';

const getApiKey = () => {
  const mapElement = document.getElementById('map')
  const apiKey = mapElement.dataset.mapboxApiKey
  return apiKey
}

const initMap = (mapElement) => {
  const apiKey = mapElement.dataset.mapboxApiKey
  mapboxgl.accessToken = apiKey;
          // style: 'mapbox://styles/mapbox/light-v10?optimize=true',
    // let styleUrl = 'mapbox://styles/liviaribeiro/ckzecd68d004415lpyjp5zhsn'
    let styleUrl = 'mapbox://styles/liviaribeiro/cl43y41bf002o14oawhwv2vku'
    if (window.innerWidth < 990) {
      styleUrl = 'mapbox://styles/liviaribeiro/ckywyr1m9000415o3k2nqxtwm?optimize=true'

    }
    const map = new mapboxgl.Map({
        container: 'map',
        style: styleUrl,
        center: [2.209666999999996, 46.232192999999995],
        minZoom: 3,
        maxZoom: 15,
        zoom: 4,
        preserveDrawingBuffer: true
    });
  if (window.innerWidth < 990) {
    map['dragPan'].disable();
  }
  return map
}
const startMap = (params) => {

    const map = initMap(params.mapElement);

    window.map = map
    map.on('load', function (eventMapLoad) {

      addGeocoded({'map': map})
      map.addControl(new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: true
      }),'bottom-right');

      // getSourceContour()
      const btns = document.querySelectorAll('.btns-tilsets')
      btns.forEach((btn) => {

        const sourceLayerName = btn.dataset.tilsetName
        map.addSource(sourceLayerName, {
            'type': 'vector',
            'url': btn.dataset.tilsetUrl
        });
        const paramsData = getParamsData({'btn': btn,
          'btns': btns,
          'sourceLayerName': sourceLayerName,
          'displayMapOneInfo': params.displayMapOneInfo,
          'eventMapLoad': eventMapLoad
        })

        layerMetric(paramsData)
        // btn_display_map(btn,map,sourceLayerName,superpose,btns,zoom,sourceLayerNameCustome)

        btn_display_map(paramsData)
      })
    btnDisplayAllSelected();
    addMultipleZoneLayer();
    goToRegion(map)
    // setPropertiesMap(map)
  });


}



const addGeocoded = (params) => {
  const pointBreak = breakpoints();

  if (window.innerWidth >= pointBreak) {
    const apiKey = getApiKey()
    const geocoder = new MapboxGeocoder({
        accessToken: apiKey,
        mapboxgl: mapboxgl,
        proximity: {longitude: 2.3488, latitude: 48.8534},
        countries: 'fr',
        trackProximity: true,
        types:'place',
        placeholder: 'Chercher par ville'
        })

    params.map.addControl(geocoder, 'top-right');
    addPoiInGeocode({'params': params, 'geocoder': geocoder})
  }
}
const addPoiInGeocode = (params) => {
  const marker = new mapboxgl.Marker({ color: '#0909B9' });
  const apiKey = getApiKey();
  params.geocoder.on('result', async (event) => {
    const point = event.result.center;
    marker.setLngLat(point).addTo(map);
  });
}

const getParamsData = (params) => {
  // btn,btnsAll,sourceLayerName
  const iconeSize = params.btn.dataset.iconeSize
  const figure = params.btn.dataset.figure
  const maxValue = params.btn.dataset.maxValue
  const color = params.btn.dataset.color
  const circleSize = params.btn.dataset.circleSize
  const superpose = params.btn.dataset.superpose
  const zoom = params.btn.dataset.zoom
  const sourceLayerNameCustome = params.btn.dataset.sourceLayerNameCustome
  const metrique = params.btn.dataset.metrique
  const tilsetLegendMarkerImage = params.btn.dataset.tilsetLegendMarkerImage
  const popupCustomize = JSON.parse(params.btn.dataset.popupCustomize)
  const urlData = params.btn.dataset.urlData
  const urlJsonData = params.btn.dataset.urlJsonData
  const urlXlsData = params.btn.dataset.urlXlsData
  const paramsData = {'map': map,
                  'sourceLayerName': params.sourceLayerName,
                  'figure': figure,
                  'maxValue': maxValue,
                  'color': color,
                  'btn': params.btn,
                  'circleSize': circleSize,
                  'metrique': metrique,
                  'sourceLayerNameCustome': sourceLayerNameCustome,
                  'tilsetLegendMarkerImage': tilsetLegendMarkerImage,
                  'zoom': zoom,
                  'superpose': superpose,
                  'tilsetLegendMarkerImage': tilsetLegendMarkerImage,
                  'btns': params.btns,
                  'displayMapOneInfo':params.displayMapOneInfo,
                  'eventMapLoad': params.eventMapLoad,
                  'urlData': urlData,
                  'urlJsonData': urlJsonData,
                  'popupCustomize': popupCustomize}
  return paramsData
}


export { startMap, getParamsData }


// const setPropertiesMap = (map) => {
//   // https://docs.mapbox.com/mapbox-gl-js/example/display-and-style-rich-text-labels/
//   // https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8/#layer-reference
//   map.setLayoutProperty('country-label', 'text-field', ['get','name_fr']);

//   map.setLayoutProperty('state-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('settlement-subdivision-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('road-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('waterway-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('natural-line-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('natural-point-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('water-line-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('water-point-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('poi-label', 'text-field', ['get', 'name_fr']);
//   map.setLayoutProperty('airport-label', 'text-field', ['get', 'name_fr']);
//   map.setPaintProperty("water", 'fill-color', "#a8e0f5");
//   map.setPaintProperty("national-park", 'fill-color', "#b4e49b");

// }
