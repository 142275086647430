const accessibility = (btn, active) => {
  if (btn) {
    if (active) {
      btn.ariaPressed = true
      // btn.ariaExpanded = true
    } else {
      btn.ariaPressed = false
      // btn.ariaExpanded = false
    }
  }

}

export { accessibility }
