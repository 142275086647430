import { popupCustomize } from './popupCustomize.js'

const popupAplat = (params) => {

    params.map.on('click', `${params.sourceLayerName}-id`, function (e) {
      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnMove: true
      });
      popupCustomize({'e': e,
          'popup': popup,
          'params': params
      })
      // if (e.features.length > 0) {
      //   const informationsHash = e.features[0].properties

      //   let text = []
      //   for (const [key, value] of Object.entries(informationsHash)) {

      //     if (params.metrique === key) {

      //       if (typeof(value) === 'number') {
      //         text.push(`<li> Metrique : ${Math.round(value * 100) / 100} </li>`)
      //       } else {
      //         text.push(`<li> Metrique : ${value} </li>`)
      //       }

      //     }
      //   }
      //   // console.log(text.join(" "))
      //   const htmlText = `<ul class="popup-atlas-culture ul-style-none">${text.join(" ")}</ul>`
      //   popup.setLngLat(e.lngLat)
      //     .setHTML(htmlText)
      //     .addTo(params.map);
      // }
    });

}

export { popupAplat }
