import { popupAplat } from './popup/popupAplat.js';
import { labelMap } from '../labelMap.js'
import { buildParamsAplatWithStr } from './utilities/set_aplat_with_str.js';
import { buildParamsAplat } from './utilities/set_aplat_with_int.js';
import { buildParamsAplatOne } from './utilities/setbuildParamsAplatOne.js';
const template_aplat = (params) => {
  // map,sourceLayerName,btn,sourceLayerNameCustome

  let aplat = params.btn.dataset.tilsetAplatInfo
  aplat = JSON.parse(aplat)
  const aplatColorList = aplat.aplat_color.split('||')
  const aplatStepList = aplat.aplat_step.split('||')


  let paramsAplat = '';
  const list_aplat_str = ['TAAV2017','ZONAGE_URBAIN','ZRR',"lib_petr_encoded"]
  const list_aplat_one = ['QP']
  if (list_aplat_str.includes(params.metrique)) {

    paramsAplat = buildParamsAplatWithStr(aplatColorList,aplatStepList,params.metrique)
  } else if (aplatStepList.length  == 1) {

    paramsAplat = buildParamsAplatOne(aplatColorList,aplatStepList,params.metrique)
  } else{

    paramsAplat = buildParamsAplat(aplatColorList,aplatStepList,params.metrique)

  }

  // ['step',["get", "metrique"],aplatColorList[0],parseInt(aplatStepList[0]),"#edf8fb",10000,"#b2e2e2",20000]
  // 'source-layer': `recipe_tileset_${sourceLayerName}`,


  // Pour mettre l'aplat de couleur en dessous des etiquettes de la carte
  // https://docs.mapbox.com/mapbox-gl-js/example/geojson-layer-in-stack/
  const firstSymbolId = labelMap(map)

  params.map.addLayer(
      {
          'id': `${params.sourceLayerName}-id`,
          'source': `${params.sourceLayerName}`,
          'source-layer': `${params.sourceLayerNameCustome}`,
          'type': 'fill',
          'paint': {
              'fill-color': paramsAplat,
              'fill-opacity': 0.75,
          },
          'layout': {
              'visibility': 'none'
          }
      },
      firstSymbolId
  );
  popupAplat(params);
}






export { template_aplat }





// const info = []
  // let i = 0
  // let stepInfo = ''
  // aplatColorList.forEach((colorInfo) => {
  //   stepInfo = parseInt(aplatStepList[i])
  //   info.push(`${stepInfo},${colorInfo}`)
  //   i = i + 1
  // })
