import { removeLegend,addLegend } from '../legend/add.js'

const addInfo = (current_legend) => {
  const btn = current_legend.querySelector('.info-cr')
  const boxCr = current_legend.nextElementSibling
  btn.addEventListener('click', (event) => {
    boxCr.classList.remove('d-none')
    removeLegend(current_legend)
  })
  removeInfo(current_legend, boxCr)
}


const removeInfo = (current_legend,boxCr) => {
  const btn = current_legend.nextElementSibling.querySelector('.remove-info-cr')

  btn.addEventListener('click', (event) => {
    boxCr.classList.add('d-none')
    addLegend(current_legend)
  })
}

export { addInfo }
