const buildParamsAplatOne = (colors,steps,metrique) => {
  let set_color = ''
  if (colors[0]) {
    set_color = colors[0]
  } else {
    set_color = "#3182bd"
  }
  const paramsAplat = set_color
  return paramsAplat
}

export { buildParamsAplatOne }
