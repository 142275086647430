const goToRegion = (map) => {
    if (map) {
      const regions = document.querySelectorAll('.region-dom-tom-btn')

      regions.forEach((region) => {
        region.addEventListener('click',(event) => {
          const regionClick = event.currentTarget
          let center = ''
          displayMetropole(regions,event)
          if (regionClick.value == "Guadeloupe") {
              center = [-61.687126, 16.2305103]

          }
          if (regionClick.value == "Guyane") {
              center = [-52.999998, 4.0039882]
          }
          if (regionClick.value == "La Réunion") {
              center = [55.5364801, -21.1307379]
          }
          if (regionClick.value == "Martinique") {
              center = [-61.0158269, 14.6367927]
          }
          if (regionClick.value == "Mayotte") {
              center = [45.1486261, -12.8253862]
          }
          if (regionClick.value == "metropole") {
              center = [2.209666999999996, 46.232192999999995]

          }

          map.flyTo({
              center: center, zoom: 5
          });
        })
      })

    }
}

const displayMetropole = (regions,event) => {
  const element = event.currentTarget.parentNode
  regions.forEach((region) => {
    if (region.parentNode != element) {
      region.parentNode.classList.remove('d-none')
    } else {
      region.parentNode.classList.add('d-none')
    }
  })
}

export { goToRegion }
