
import { startMap } from 'components/map/startMap.js';


document.addEventListener('turbolinks:load', () => {
  const mapElement = document.getElementById('map')
  const legendShow = document.getElementById('map-legend-show')
  if (mapElement) {
    if (legendShow) {
      startMap({'mapElement': mapElement,
        'displayMapOneInfo':true});
    } else {
      startMap({'mapElement': mapElement,
        'displayMapOneInfo':false});

    }
  }


})
