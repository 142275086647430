import { addLegend, removeLegend } from './legend/add.js';
const checkSuperpose = (params) => {
  const visibleSLN = params.map.getLayoutProperty(`${params.sLN}-id`, 'visibility')
  // console.log("Superposition :",superpose)
  const class_active = 'btn-active'
  params.btns.forEach((btn) => {

    const sourceLayerName = btn.dataset.tilsetName
    const visible = params.map.getLayoutProperty(`${sourceLayerName}-id`, 'visibility')


    if (btn.dataset.superpose === 'false' & btn != params.current_btn) {
      // Explication :
      // On ne peut pas superposer et ce n'est pas sur le bouton sur lequel on a cliqué
      // Cas d'usage : Lorsque l'on clique sur un bouton que l'on peut superposer.
      // Nous avons envie que le bouton qui ne peut pas être superposé soit desactivé sur la carte.
        // Obsolete
        // params.map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'none');
        // btn.classList.remove(class_active)

    } else if (btn.dataset.superpose === 'false' & btn === params.current_btn) {
      if (visible == 'none') {
        params.map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'visible');
        btn.classList.add(class_active)

        const legend = document.getElementById(`${btn.dataset.legendTilsetId}`)
        addLegend(legend,btn)


        no_display_map(params.btns,btn,params.map,class_active)

      } else {
        params.map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'none');
        btn.classList.remove(class_active)

        const legend = document.getElementById(`${btn.dataset.legendTilsetId}`)
        removeLegend(legend,btn)



      }
    } else if (btn.dataset.superpose === 'true' & btn === params.current_btn)
      if (visible == 'none') {
        params.map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'visible');
        btn.classList.add(class_active)

        const legend = document.getElementById(`${btn.dataset.legendTilsetId}`)
        addLegend(legend,btn)

      } else {
        params.map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'none');
        btn.classList.remove(class_active)
        const legend = document.getElementById(`${btn.dataset.legendTilsetId}`)
        removeLegend(legend,btn)


      }
    }
  )
}

const no_display_map = (btns,c_btn,map,class_active) => {

  btns.forEach((btn) => {

    if (btn != c_btn) {
      if (btn.dataset.figure === 'aplat') {
        const sourceLayerName = btn.dataset.tilsetName
        map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'none');
        btn.classList.remove(class_active)

        const legend = document.getElementById(`${btn.dataset.legendTilsetId}`)
        removeLegend(legend,btn)
      }
    }
  })
}



export { checkSuperpose }
