import { addInfo } from '../infoComplementary/add.js';
import { addLegendMobileOnClick } from '../../../responsive/legendMobile.js'
import { addUrlDownload, removeUrlDownload } from '../download/urlDownload.js'
import { accessibility } from '../accessibility.js';
const addLegend = (current_legend,btn) => {
  current_legend.classList.remove('d-none')
  removeLegendIcone(current_legend)
  addInfo(current_legend)
  addLegendMobileOnClick()
  addUrlDownload(btn,current_legend)
  accessibility(btn, true)

}
const removeLegend = (legend,btn) => {
  legend.classList.add('d-none')
  addLegendMobileOnClick()
  removeUrlDownload(btn,legend)
  accessibility(btn, false)
}


const removeLegendIcone = (legend) => {

  const btnRemoveLegend = legend.querySelector('.remove-legend')
  const sourceLayerName = legend.dataset.tilsetName
  const btn = document.querySelector(`.tileset_${sourceLayerName}`)

  btnRemoveLegend.addEventListener('click', (event) => {
    map.setLayoutProperty(`${sourceLayerName}-id`, 'visibility', 'none');
    removeLegend(legend);
    btn.classList.remove('btn-active')


  })
}



export { addLegend, removeLegend,removeLegendIcone}
