import { popupCustomize } from './popupCustomize.js'
const popupMarker = (params) => {
  // map,sourceLayerName
  // if (params.displayMapOneInfo) {
  // } else {
  //   popUpClick(params)
  // }

  popUpClick(params)
}

const popUpClick = (params) => {
    params.map.on('click', `${params.sourceLayerName}-id`, function (e) {
      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnMove: true
      });
      popupCustomize({'e': e,
                      'popup': popup,
                      'params': params
                    })


    });
  }

export { popupMarker }
