import { popupCircle } from './popup/popupCircle.js';
import { labelMap } from '../labelMap.js'

const template_circle = (params) => {

    const expression1 = ['*', parseInt(params.circleSize), ['/',['sqrt', ["get", `${params.metrique}`]],['sqrt', parseInt(params.maxValue)]]]
    const firstSymbolId = labelMap(map)
    params.map.addLayer( {
      'id': `${params.sourceLayerName}-id`,
      'source': `${params.sourceLayerName}`,
      'source-layer': `${params.sourceLayerNameCustome}`,
      'type': 'circle',
      "minzoom": params.map.transform._minZoom,
      "maxzoom": params.map.transform._maxZoom,
      'paint': {
                'circle-radius': expression1,
                'circle-color': params.color,
                'circle-opacity': 0.75,
                },
                'layout': {
                        // make layer not none by default
                'visibility': 'none'
                }
    }, firstSymbolId
    )
    popupCircle(params);
}

export { template_circle }
