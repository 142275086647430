import { popupMarker } from './popup/popupMarker.js';

const template_marker = (params) => {

  params.map.loadImage(
    `${params.tilsetLegendMarkerImage}`,
    (error, image) => {
    if (error) throw error;
      params.map.addImage(`custom-marker-${params.sourceLayerName}`, image);
  })

  map.addLayer({
    'id': `${params.sourceLayerName}-id`,
    'type': 'symbol',
    'source': `${params.sourceLayerName}`,
    'source-layer': `${params.sourceLayerNameCustome}`,
    'layout': {
    'icon-image': `custom-marker-${params.sourceLayerName}`,
    'icon-size': parseFloat(params.btn.dataset.iconeSize),
    'visibility': 'none',
    'icon-allow-overlap': true
    },
  })

  popupMarker(params);
}


export { template_marker}
