import { template_circle } from './templates/circle.js'
import { template_aplat } from './templates/aplat.js'
import { template_marker } from './templates/marker.js'
const layerMetric = (params) => {
  if (params.figure == 'circle') {
    template_circle(params)
  } else if (params.figure == 'aplat') {
    template_aplat(params)
  } else if (params.figure == 'marker') {
    template_marker(params)
  }
}




export { layerMetric }
