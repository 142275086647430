const buildParamsAplatWithStr = (colors,steps,metrique) => {
  const list_color_1 = ['ZONAGE_URBAIN','ZRR',"lib_petr_encoded"]
  const list_color_2 = ['TAAV2017']
  let last_color = '#ffffff'
  steps = steps.map(step => step.trim());
  if (list_color_1.includes(metrique)) {
    last_color = '#ffffff'

  } else if(list_color_2.includes(metrique)) {
    last_color = '#5e3c99'
  }

  if (colors.length == 1) {
    const paramsAplat = [
                  "match",
                  ["get", metrique],
                  [
                    `${steps[0]}`
                  ],
                  `${colors[0]}`,
                  colors[0]]
    return paramsAplat
  } else if (colors.length == 2) {
    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    last_color]
    return paramsAplat
  } else if (colors.length == 3) {
    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    [
                      `${steps[2]}`
                    ],
                    `${colors[2]}`,
                    last_color]
    return paramsAplat

  } else if (colors.length == 4) {
    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    [
                      `${steps[2]}`
                    ],
                    `${colors[2]}`,
                    [
                      `${steps[3]}`
                    ],
                    `${colors[3]}`,
                    last_color]
    return paramsAplat
  } else if (colors.length == 5) {
    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    [
                      `${steps[2]}`
                    ],
                    `${colors[2]}`,
                    [
                      `${steps[3]}`
                    ],
                    `${colors[3]}`,
                    [
                      `${steps[4]}`
                    ],
                    `${colors[4]}`,
                    last_color]
    return paramsAplat
  } else if (colors.length == 6) {

    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    [
                      `${steps[2]}`
                    ],
                    `${colors[2]}`,
                    [
                      `${steps[3]}`
                    ],
                    `${colors[3]}`,
                    [
                      `${steps[4]}`
                    ],
                    `${colors[4]}`,
                    [
                      `${steps[5]}`
                    ],
                    `${colors[5]}`,
                    last_color]

    return paramsAplat
  } else if (colors.length == 7) {
    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    [
                      `${steps[2]}`
                    ],
                    `${colors[2]}`,
                    [
                      `${steps[3]}`
                    ],
                    `${colors[3]}`,
                    [
                      `${steps[4]}`
                    ],
                    `${colors[4]}`,
                    [
                      `${steps[5]}`
                    ],
                    `${colors[5]}`,
                    [
                      `${steps[6]}`
                    ],
                    `${colors[6]}`,
                    last_color]

    return paramsAplat
  } else if (colors.length == 8) {


    const paramsAplat = [
                    "match",
                    ["get", metrique],
                    [
                      `${steps[0]}`
                    ],
                    `${colors[0]}`,
                    [
                      `${steps[1]}`
                    ],
                   `${colors[1]}`,
                    [
                      `${steps[2]}`
                    ],
                    `${colors[2]}`,
                    [
                      `${steps[3]}`
                    ],
                    `${colors[3]}`,
                    [
                      `${steps[4]}`
                    ],
                    `${colors[4]}`,
                    [
                      `${steps[5]}`
                    ],
                    `${colors[5]}`,
                    [
                      `${steps[6]}`
                    ],
                    `${colors[6]}`,
                    [
                      `${steps[7]}`
                    ],
                    `${colors[7]}`,
                    last_color]

    return paramsAplat
  }

}

export { buildParamsAplatWithStr }
