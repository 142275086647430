import { popupCustomize } from './popupCustomize.js'

const popupCircle = (params) => {
    params.map.on('click', `${params.sourceLayerName}-id`, function (e) {
      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnMove: true
      });
      popupCustomize({'e': e,
                'popup': popup,
                'params': params
              })
      // if (e.features.length > 0) {
      //   const informationsHash = e.features[0].properties
      //   let text = []
      //   for (const [key, value] of Object.entries(informationsHash)) {
      //     text.push(`<li> ${key} : ${value} </li>`)
      //   }
      //   console.log(text.join(" "))
      //   const htmlText = `<ul class="popup-atlas-culture">${text.join(" ")}</ul>`
      //   popup.setLngLat(e.lngLat)
      //     .setHTML(htmlText)
      //     .addTo(params.map);
      // }
    });

}

export { popupCircle }
