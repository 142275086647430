const labelMap = () => {
    const layers = map.getStyle().layers;
    let firstSymbolId;
    for (const layer of layers) {
    if (layer.type === 'symbol') {
    firstSymbolId = layer.id;
    break;
    }
  }
  return firstSymbolId
}

export { labelMap }
