const addUrlDownload = (btn,legend) => {
  const boxDownloadUrls = document.getElementById('download-url-data')
  if (boxDownloadUrls) {
    let element = ''

    if (btn) {
      if (btn.dataset.urlData) {
        element = `
          <li id='data-url-${btn.dataset.tilsetName}'>
            ${btn.innerText} : <br>
            <b>Formats de fichiers</b>
            <ul>
              <li>
                <a rel='nofollow' href="${btn.dataset.urlData}" target="_blank" download=${btn.dataset.tilsetName}>
                  Csv
                </a>
              </li>
              <li>
                <a rel='nofollow' href="${btn.dataset.urlJsonData}" target="_blank" download=${btn.dataset.tilsetName}>
                  Json
                </a>
              </li>
              <li>
                <a rel='nofollow' href="${btn.dataset.urlXlsData}" target="_blank" download=${btn.dataset.tilsetName}>
                  Excel
                </a>
              </li>
            </ul>
          </li>
        `
      } else {
        element = `
          <li id='data-url-${btn.dataset.tilsetName}'>
              Téléchargement non disponible : ${btn.innerText}
            </a>
          </li>
         `
      }
      boxDownloadUrls.insertAdjacentHTML('beforeend', element)

    }

    }
}

const removeUrlDownload = (btn,legend) => {
  // const urlDataTilset = document.getElementById(`url-data-${btn.id}`)
  const boxDownloadUrls = document.getElementById('download-url-data')
  if (boxDownloadUrls) {
    const lis = boxDownloadUrls.querySelectorAll('li')
    if (lis) {
      lis.forEach((element) => {
        if (legend) {
          if (btn) {
            if (btn.dataset.tilsetName == legend.dataset.tilsetName) {
              if (!btn.classList.contains('btn-active')) {
                const urlElement = document.getElementById(`data-url-${legend.dataset.tilsetName}`)
                if (urlElement) {
                  urlElement.remove()
                }
              }
            }
          }
        }
      })

    }
  }

}
export { addUrlDownload, removeUrlDownload }
