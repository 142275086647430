import { labelMap } from '../labelMap.js'

const zoneLayers = (zoneLayerID,zoneLayer,zoneSourceLayer,minZoom,maxZoom) => {
    const firstSymbolId = labelMap(map)
    map.addLayer(
    {
        'id': zoneLayerID,
        'source': zoneLayer,
        'source-layer': zoneSourceLayer,
        'minzoom': minZoom,
        'maxzoom': maxZoom,
        'type': 'fill',
        'paint': {
            'fill-color': "#627BC1",
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.25,
                0
                ]
        },
        'layout': {
            'visibility': 'visible'
        }
    }, firstSymbolId
  );

}

const zoneLayersLine = (zoneLineID,zoneLayer,zoneSourceLayer,minZoom,lineWidth) => {
  const firstSymbolId = labelMap(map)
  map.addLayer({
  'id': zoneLineID,
  'type': 'line',
  'source': zoneLayer,
  'source-layer': zoneSourceLayer,
  'minzoom': minZoom,
  'layout': {
      'visibility': 'visible'
  },
  'paint': {
      'line-color': 'rgba(200,200,200,0.1)',
      'line-width': lineWidth
  }
  }, firstSymbolId);
}

export { zoneLayers, zoneLayersLine }
